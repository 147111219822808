import './Test9.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import closeIcon from '../../assets/icons/close.png';

export default function Test9() {
    const questions = [
        "Я чувствую себя здоровым.",
        "Физически я мало на что способен.",
        "Я чувствую себя активным.",
        "Все, что я делаю, доставляет мне удовольствие.",
        "Я чувствую себя усталым.",
        "Мне кажется, я многое успеваю за день.",
        "Когда я занимаюсь чем-либо, я могу сконцентрироваться на этом.",
        "Физически я способен на многое.",
        "Я боюсь дел, которые мне необходимо сделать.",
        "Я думаю, что за день выполняю очень мало дел.",
        "Я могу хорошо концентрировать внимание.",
        "Я чувствую себя отдохнувшим.",
        "Мне требуется много усилий для концентрации внимания.",
        "Физически я чувствую себя в плохом состоянии.",
        "У меня много планов.",
        "Я быстро устаю.",
        "Я очень мало успеваю сделать.",
        "Мне кажется, что я ничего не делаю.",
        "Мои мысли легко рассеиваются.",
        "Физически я чувствую себя в прекрасном состоянии.",
    ]
    const [qCnt, setQCnt] = useState(0);
    const [answers, setAnswers] = useState([-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]);
    const handleButtonClick = (value) => {
        const newAnswers = [...answers];
        newAnswers[qCnt] = value;
        setAnswers(newAnswers);
    }
    return (
        <div className="Test9">
            <div className="block">
                {qCnt < 20 ? <div className='progress' style={{ width: `${((qCnt+1)/21)*100}%` }}></div> : 
                <div className='progress' style={{ width: '100%' }}></div>}
                {qCnt < 20 ? <h2><span>{qCnt+1} / 20. </span>{questions[qCnt]}</h2> : <></>}
                {qCnt > 19 ? <h2>Ваш результат: {answers.reduce((partialSum, a) => partialSum + a, 0)}</h2> : <></>} {/* Далее h4 с результатами answers.reduce((partialSum, a) => partialSum + a, 0) - сумма */}
                {qCnt > 19 ? <h4>В норме общее количество баллов не должно превышать 20—30.</h4> : <></>}
                {qCnt > 19 ? <h4>Кроме общего результата по шкале, состояние может оцениваться по следующим субшкалам:</h4> : <></>}
                {qCnt > 19 ? <h4>Общая астения: {answers[0] + answers[4] + answers[11] + answers[15]}</h4> : <></>}
                {qCnt > 19 ? <h4>Пониженная активность: {answers[2] + answers[5] + answers[9] + answers[16]}</h4> : <></>}
                {qCnt > 19 ? <h4>Снижение мотивации: {answers[3] + answers[8] + answers[14] + answers[17]}</h4> : <></>}
                {qCnt > 19 ? <h4>Физическая астения: {answers[1] + answers[7] + answers[13] + answers[19]}</h4> : <></>}
                {qCnt > 19 ? <h4>Психическая астения: {answers[6] + answers[10] + answers[12] + answers[18]}</h4> : <></>}
                {qCnt > 19 ? <h4>Если суммарный балл по одной из субшкал выше 12, то это может быть предварительным основанием для  постановки диагноза "астенический синдром".</h4> : <></>}
                {qCnt < 20 ? <div className={qCnt !== 0 && qCnt !== 2 && qCnt !== 3 && qCnt !== 5 && qCnt !== 6 && qCnt !== 7 && qCnt !== 10 && qCnt !== 11 && qCnt !== 14 && qCnt !== 19 ? "choose" : "choose-reversed"}>
                    {qCnt !== 0 && qCnt !== 2 && qCnt !== 3 && qCnt !== 5 && qCnt !== 6 && qCnt !== 7 && qCnt !== 10 && qCnt !== 11 && qCnt !== 14 && qCnt !== 19 ? <h6>Да, это правда</h6> : <h6>Нет, это неправда</h6>}
                    <button className={answers[qCnt] === 5 ? 'ans active' : 'ans'} onClick={() => handleButtonClick(5)}>5</button>
                    <button className={answers[qCnt] === 4 ? 'ans active' : 'ans'} onClick={() => handleButtonClick(4)}>4</button>
                    <button className={answers[qCnt] === 3 ? 'ans active' : 'ans'} onClick={() => handleButtonClick(3)}>3</button>
                    <button className={answers[qCnt] === 2 ? 'ans active' : 'ans'} onClick={() => handleButtonClick(2)}>2</button>
                    <button className={answers[qCnt] === 1 ? 'ans active' : 'ans'} onClick={() => handleButtonClick(1)}>1</button>
                    {qCnt !== 0 && qCnt !== 2 && qCnt !== 3 && qCnt !== 5 && qCnt !== 6 && qCnt !== 7 && qCnt !== 10 && qCnt !== 11 && qCnt !== 14 && qCnt !== 19 ? <h6>Нет, это неправда</h6> : <h6>Да, это правда</h6>}
                </div> : <></>}
                {qCnt < 20 ? <button className={qCnt > 0 ? 'prev active' : 'prev'} onClick={() => qCnt > 0 ? setQCnt(qCnt-1) : setQCnt(qCnt)}>Назад</button> : <></>}
                {qCnt < 20 ? <button className='next active' onClick={() => answers[qCnt] !== -1 ? setQCnt(qCnt+1) : alert('Введите ответ')}>Далее</button> : <></>}
            </div>
        </div>
    )
}