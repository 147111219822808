import './Test7.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import closeIcon from '../../assets/icons/close.png';

export default function Test7() {
    const questions = [
        "У близкого вам человека есть проблемы с памятью?",
        "Если это так, стала ли она хуже, чем несколько лет назад?",
        "Ваш близкий повторяет один и тот же вопрос или высказывает одну и ту же мысль несколько раз в течение дня?",
        "Забывает ли он о назначенных встречах или событиях?",
        "Кладет ли он вещи в непривычные места чаще одного раза в месяц?",
        "Подозревает ли других в том, что они прячут или крадут его вещи, когда не может найти их?",
        "Часто ли он испытывает трудности при попытке вспомнить час, день, месяц, год; пытается вспомнить одну и ту же дату чаще одного раза в день?",
        "Он испытывает проблему с ориентацией в незнакомом месте?",
        "Усиливается ли растерянность за пределами дома или в поездках?",
        "Возникают ли у вашего близкого проблемы при произведении расчетов, например, при подсчете сдачи в магазине?",
        "Есть ли трудности с оплатой счетов или с произведением других финансовых операций?",
        "Забывает ли он принимать лекарства? Бывают ли эпизоды, когда он не может вспомнить, принимал ли он уже лекарство?",
        "Есть ли проблемы с управлением автомобилем? Переживаете ли вы за его способность справляться с управлением?",
        "Возникают ли трудности при использовании бытовых приборов, телефона, телевизионного пульта?",
        "Испытывает ли он затруднения, выполняя работу по дому (со скидкой на естественные физические ограничения)?",
        "Потерял ли он интерес к привычным увлечениям: занятиям на воздухе, рукоделию, танцам и другим (со скидкой на естественные физические ограничения)?",
        "Может ли он потеряться на знакомой территории (например, рядом с собственным домом)?",
        "Утрачивает ли он чувство правильного направления движения?",
        "Случается ли, что ваш близкий не только забывает имена, но и не может вспомнить нужное слово?",
        "Путает ли ваш близкий имена родственников или друзей?",
        "Если ли у него проблемы с узнаванием знакомых людей?"
    ]
    const [qCnt, setQCnt] = useState(0);
    const [answers, setAnswers] = useState([-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1]);
    const handleButtonClick = (value) => {
        const newAnswers = [...answers];
        newAnswers[qCnt] = value;
        setAnswers(newAnswers);
    }
    return (
        <div className="Test7">
            <div className="block">
                {qCnt < 21 ? <div className='progress' style={{ width: `${((qCnt+1)/21)*100}%` }}></div> : <></>}
                {qCnt === 21 ? <div className='progress' style={{ width: '100%' }}></div> : <></>}
                {qCnt < 21 ? <h2><span>{qCnt+1} / 21. </span>{questions[qCnt]}</h2> : <></>}
                {qCnt === 21 ? <h2>Ваш результат: {answers.reduce((partialSum, a) => partialSum + a, 0)}</h2> : <></>}
                {qCnt === 21 ? <h4>{answers.reduce((partialSum, a) => partialSum + a, 0) < 5 ? 'По результатам тестирования когнитивные расстройства не выявлены, показатели в пределах нормы. Значимые проблемы в когнитивной сфере отсутствуют. Важно помнить, что основополагающим методом профилактики деменции является ранняя диагностика и своевременное обращение к специалисту. Будьте здоровы!' : ''}</h4> : <></>}
                {qCnt === 21 ? <h4>{answers.reduce((partialSum, a) => partialSum + a, 0) >= 5 && answers.reduce((partialSum, a) => partialSum + a, 0) <= 14  ? 'Результаты тестирования могут свидетельствовать о наличии умеренных когнитивных нарушений, которые лучше не оставлять без внимания. Нередко такие нарушения - первая стадия в развитии более серьезных проблем. У 15% лиц, которым поставлен диагноз «умеренные когнитивные расстройства», в течение года развиваются более тяжелые нарушения. Рекомендуется обратиться к специалисту, чтобы получить более точную диагностику.' : ''}</h4> : <></>}
                {qCnt === 21 ? <h4>{answers.reduce((partialSum, a) => partialSum + a, 0) > 14 ? 'Результаты тестирования могут свидетельствовать о довольно высокой вероятности деменции у близкого вам человека. Рекомендовано в оперативные сроки обратиться к специалисту (неврологу, нейропсихологу или психотерапевту) для более глубоко обследования, выявления причин когнитивных нарушений и постановки диагноза.' : ''}</h4> : <></>}
                {qCnt < 21 ? <div className="choose">
                    <button className={answers[qCnt] > 0 ? 'ans active' : 'ans'} onClick={() => qCnt in [2, 5, 10, 16, 19, 20] ? handleButtonClick(2) : handleButtonClick(1)}>Да</button>
                    <button className={answers[qCnt] === 0 ? 'ans active' : 'ans'} onClick={() => handleButtonClick(0)}>Нет</button>
                </div> : <></>}
                {qCnt < 21 ? <button className={qCnt > 0 ? 'prev active' : 'prev'} onClick={() => qCnt > 0 ? setQCnt(qCnt-1) : setQCnt(qCnt)}>Назад</button> : <></>}
                {qCnt < 21 ? <button className='next active' onClick={() => answers[qCnt] !== -1 ? setQCnt(qCnt+1) : alert('Введите ответ')}>Далее</button> : <></>}
            </div>
        </div>
    )
}