import './Test6.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import closeIcon from '../../assets/icons/close.png';

export default function Test6() {
    const questions = [
        "Какой сейчас идет год?",
        "Какой сейчас месяц?",
        "Сейчас будут показаны имя и адрес. Вам предстоит запомнить их. Вы должны будете повторить их позже, когда понадобится.",
        "Который сейчас час?",
        "Проведите обратный счет от 20 до 1.",
        "Перечислите месяца года в обратном порядке:",
        "Запишите раннее показанный адрес.",
    ]
    const [qCnt, setQCnt] = useState(0);
    const [answers, setAnswers] = useState([-1, -1, 1, -1, -1, '', -1]);
    const [result, setResult] = useState(0);
    const handleButtonClick = (value) => {
        const newAnswers = [...answers];
        newAnswers[qCnt] = value;
        setAnswers(newAnswers);
    }
    const handleButtonClick2 = (value) => {
        const newAnswers = [...answers];
        newAnswers[qCnt] = String(answers[qCnt]) + String(value);
        setAnswers(newAnswers);
    }
    const handleChange = (event) => {
        const { value } = event.target;
        handleButtonClick(value);
    }

    useEffect(() => {
        if (qCnt === 7) {
            let newResult = 0;
            if (answers[0] !== new Date().getFullYear().toString()) {
                newResult += 4;
            } 
            if (answers[1] !== (new Date().getMonth() + 1)) {
                newResult += 3;
            }
            if ((answers[3] !== new Date().getHours().toString()) && (answers[3] !== (new Date().getHours().toString()) + 1)) {
                newResult += 3;
            }
            if (answers[4] !== '20 19 18 17 16 15 14 13 12 11 10 9 8 7 6 5 4 3 2 1') {
                newResult += 3;
            }
            if (answers[5] !== '121110987654321') {
                newResult += 2;
            }
            if ((answers[6] !== 'Михаил Смирнов город Тула улица Пушкина дом 42') && (answers[6] !== 'михаил смирнов город тула улица пушкина дом 42')) {
                newResult += 5;
            }
            setResult(newResult);
        }
    }, [qCnt, answers]);

    return (
        <div className="Test6">
            <div className="block">
                {qCnt < 7 ? <div className='progress' style={{ width: `${((qCnt+1)/7)*100}%` }}></div> : 
                <div className='progress' style={{ width: '100%' }}></div>}
                {qCnt < 7 ? <h2><span>{qCnt+1} / 7. </span>{questions[qCnt]}</h2> : <></>}
                {qCnt === 0 ? <div className='q1'>
                    <input type="text" maxLength={4} onChange={handleChange} value={answers[0] === -1 ? null : answers[0]} placeholder='XXXX'/>
                </div> : <></>}
                {qCnt === 1 ? <div className='q2'>
                    <button onClick={() => handleButtonClick(1)} className={1 === answers[qCnt] ? 'active' : ''}>Январь</button>
                    <button onClick={() => handleButtonClick(2)} className={2 === answers[qCnt] ? 'active' : ''}>Февраль</button>
                    <button onClick={() => handleButtonClick(3)} className={3 === answers[qCnt] ? 'active' : ''}>Март</button>
                    <button onClick={() => handleButtonClick(4)} className={4 === answers[qCnt] ? 'active' : ''}>Апрель</button>
                    <button onClick={() => handleButtonClick(5)} className={5 === answers[qCnt] ? 'active' : ''}>Май</button>
                    <button onClick={() => handleButtonClick(6)} className={6 === answers[qCnt] ? 'active' : ''}>Июнь</button>
                    <button onClick={() => handleButtonClick(7)} className={7 === answers[qCnt] ? 'active' : ''}>Июль</button>
                    <button onClick={() => handleButtonClick(8)} className={8 === answers[qCnt] ? 'active' : ''}>Август</button>
                    <button onClick={() => handleButtonClick(9)} className={9 === answers[qCnt] ? 'active' : ''}>Сентябрь</button>
                    <button onClick={() => handleButtonClick(10)} className={10 === answers[qCnt] ? 'active' : ''}>Октябрь</button>
                    <button onClick={() => handleButtonClick(11)} className={11 === answers[qCnt] ? 'active' : ''}>Ноябрь</button>
                    <button onClick={() => handleButtonClick(12)} className={12 === answers[qCnt] ? 'active' : ''}>Декабрь</button>
                </div> : <></>}
                {qCnt === 2 ? <div className='q3'>
                    <h3>Михаил Смирнов город Тула улица Пушкина дом 42</h3>
                </div> : <></>}
                {qCnt === 3 ? <div className='q4'>
                    <input type="text" maxLength={2} onChange={handleChange} value={answers[3] === -1 ? null : answers[3]} placeholder='XX'/>
                </div> : <></>}
                {qCnt === 4 ? <div className='q5'>
                    <h3>Введите числа от 20 до 1 включительно через пробел:</h3>
                    <input type="text" onChange={handleChange} value={answers[4] === -1 ? null : answers[4]} placeholder='X X X X X X X X X X X X X X X X X X X X'/>
                </div> : <></>}
                {qCnt === 5 ? <div className='q6'>
                    <button id="m6" onClick={() => { handleButtonClick2(6); document.getElementById("m6").style.display = "none"; }}>Июнь</button>
                    <button id="m4" onClick={() => { handleButtonClick2(4); document.getElementById("m4").style.display = "none"; }}>Апрель</button>
                    <button id="m8" onClick={() => { handleButtonClick2(8); document.getElementById("m8").style.display = "none"; }}>Август</button>
                    <button id="m2" onClick={() => { handleButtonClick2(2); document.getElementById("m2").style.display = "none"; }}>Февраль</button>
                    <button id="m3" onClick={() => { handleButtonClick2(3); document.getElementById("m3").style.display = "none"; }}>Март</button>
                    <button id="m11" onClick={() => { handleButtonClick2(11); document.getElementById("m11").style.display = "none"; }}>Ноябрь</button>
                    <button id="m10" onClick={() => { handleButtonClick2(10); document.getElementById("m10").style.display = "none"; }}>Октябрь</button>
                    <button id="m9" onClick={() => { handleButtonClick2(9); document.getElementById("m9").style.display = "none"; }}>Сентябрь</button>
                    <button id="m5" onClick={() => { handleButtonClick2(5); document.getElementById("m5").style.display = "none"; }}>Май</button>
                    <button id="m1" onClick={() => { handleButtonClick2(1); document.getElementById("m1").style.display = "none"; }}>Январь</button>
                    <button id="m12" onClick={() => { handleButtonClick2(12); document.getElementById("m12").style.display = "none"; }}>Декабрь</button>
                    <button id="m7" onClick={() => { handleButtonClick2(7); document.getElementById("m7").style.display = "none"; }}>Июль</button>
                    {answers[qCnt].length === 15 ? <h3>Готово!</h3> : <></>}
                </div> : <></>}
                {qCnt === 6 ? <div className='q7'>
                    <h3>Введите адрес через пробел в формате "Имя Фамилия город Название улица Название дом Номер":</h3>
                    <input type="text" onChange={handleChange} value={answers[6] === -1 ? null : answers[6]} placeholder='имяХ фамилияХ город X улица X дом X'/>
                </div> : <></>}
                {qCnt === 7 && (
                    <>
                        <h2>Ваш результат: {result} баллов</h2>
                    </>
                )}
                {(qCnt === 7 && result < 8) ? <h3>По результатам тестирования когнитивные расстройства не выявлены, показатели в пределах нормы. Значимые проблемы в когнитивной сфере отсутствуют. Важно помнить, что основополагающим методом профилактики деменции является ранняя диагностика и своевременное обращение к специалисту. Будьте здоровы!</h3> : <></>}
                {(qCnt === 7 && (result === 8 || result === 9)) ? <h3>По результатам тестирования выявлены умеренные когнитивные расстройства. Они представляют собой переходное состояние, при котором происходит снижение когнитивной функции, не приводящее к социальной дезадаптации. Умеренные когнитивные нарушения не обязательно вызваны развивающейся деменцией. Они могут быть признаком стресса, тревоги и депрессии, снижения функции щитовидной железы, дефицитных состояний, например недостатка витамина В12 или перенесенной инфекции COVID-19. Рекомендуется обратиться к специалисту, чтобы получить более точную диагностику.</h3> : <></>}
                {(qCnt === 7 && result >= 10) ? <h3>По результатам тестирования выявлены выраженные когнитивные расстройства. Рекомендовано в оперативные сроки обратиться к специалисту (неврологу, нейропсихологу или психотерапевту) для более глубоко обследования, выявления причин когнитивных нарушений и постановки диагноза.</h3> : <></>}
                {qCnt < 7 ? <button className={qCnt > 0 ? 'prev active' : 'prev'} onClick={() => qCnt > 0 ? setQCnt(qCnt-1) : setQCnt(qCnt)}>Назад</button> : <></>}
                {qCnt < 7 ? <button className='next active' onClick={() => answers[qCnt] !== -1 ? setQCnt(qCnt+1) : alert('Введите ответ')}>Далее</button> : <></>}
            </div>
        </div>
    )
}
