import './Test5.css'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import closeIcon from '../../assets/icons/close.png';

export function Test5() {
    const questions = [
        "Прочитайте вслух данные слова:",
        "Каждое слово принадлежит своей категории. Что из этого - игра?",
        "Каждое слово принадлежит своей категории. Что из этого - посуда?",
        "Каждое слово принадлежит своей категории. Что из этого - сообщение?",
        "Каждое слово принадлежит своей категории. Что из этого - организация?",
        "Чуть позже вам потребуется назвать слова из прошлого вопроса.",
        "Посчитайте от 20 до 1 вслух.",
        "Назовите четыре слова, данные вам для запоминания ранее:",
    ]
    const [qCnt, setQCnt] = useState(0);
    const [answers, setAnswers] = useState([1, -1, -1, -1, -1, 1, 1, 1]);
    const [mistakes1, setMistakes1] = useState(0);
    const [mistakes2, setMistakes2] = useState(0);
    const [mistakes3, setMistakes3] = useState(0);
    const [mistakes4, setMistakes4] = useState(0);
    const [word, setWord] = useState(['','','','']);
    const [hintsClicked, setHintsClicked] = useState([0, 0, 0, 0]);
    const [result, setResult] = useState(0);
    const [taskToDO, setTaskToDo] = useState("Посчитайте вслух ");
    const [remainingTime, setRemainingTime] = useState(null);

    const handleButtonClick = (value) => {
        const newAnswers = [...answers];
        newAnswers[qCnt] = value;
        setAnswers(newAnswers);
    }

    const handleHintClick = (index) => {
        const newHintsClicked = [...hintsClicked];
        newHintsClicked[index] = 1;
        setHintsClicked(newHintsClicked);
    }

    const handleInputChange = (index, event) => {
        const newValue = event.target.value;
        const newWord = [...word];
        newWord[index] = newValue.toLowerCase(); // приводим к нижнему регистру для сравнения
        setWord(newWord);
    };

    useEffect(() => {
        if (qCnt === 6) {
            let secondsLeft = 60; // 1 minute
            const timeoutId = setTimeout(() => {
                setQCnt(prevQCnt => prevQCnt + 1);
            }, 60000); // 1 minute in milliseconds

            const intervalId = setInterval(() => {
                secondsLeft -= 1;
                setRemainingTime(secondsLeft);
            }, 1000); // Update every second

            return () => {
                clearTimeout(timeoutId);
                clearInterval(intervalId);
            };
        } else {
            setRemainingTime(null);
        }
    }, [qCnt]);

    useEffect(() => {
        let tempResult = 0;
        // Подсчёт результатов
        for (let i = 0; i < word.length; i++) {
            if (['блюдце', 'шашки', 'телеграмма', 'красный крест'].includes(word[i])) {
                tempResult += 2;
            }
        }
        // Вычитание очков за использованные подсказки
        tempResult -= hintsClicked.reduce((acc, curr) => acc + curr, 0);
        if (tempResult < 0) {
            tempResult = 0;
        }
        setResult(tempResult);
    }, [word, hintsClicked]);

    return (
        <div className="Test5">
            <div className="block">
                {qCnt < 8 ? <div className='progress' style={{ width: `${((qCnt+1)/8)*100}%` }}></div> : 
                    <div className='progress' style={{ width: '100%' }}></div>}
                {qCnt < 8 ? <h2><span>{qCnt+1} / 8. </span>{questions[qCnt]}</h2> : <></>}
                {qCnt === 0 ? <h4>Шашки, Блюдце, Телеграмма, Красный Крест</h4> : <></>}
                {qCnt === 1 ? (
                    <div className='q'>
                        <button onClick={() => {
                            if (mistakes1 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes1(mistakes1+1);
                                alert('Неверно!');
                            }
                        }}>Блюдце</button>
                        <button onClick={() => {
                            alert('Верно!');
                            setQCnt(qCnt + 1);
                        }}>Шашки</button>
                        <button onClick={() => {
                            if (mistakes1 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes1(mistakes1+1);
                                alert('Неверно!');
                            }
                        }}>Красный Крест</button>
                        <button onClick={() => {
                            if (mistakes1 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes1(mistakes1+1);
                                alert('Неверно!');
                            }
                        }}>Телеграмма</button>
                    </div>
                ) : <></>}
                {qCnt === 2 ? (
                    <div className='q'>
                        <button onClick={() => {
                            if (mistakes2 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes2(mistakes2+1);
                                alert('Неверно!');
                            }
                        }}>Телеграмма</button>
                        <button onClick={() => {
                            alert('Верно!');
                            setQCnt(qCnt + 1);
                        }}>Блюдце</button>
                        <button onClick={() => {
                            if (mistakes2 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes2(mistakes2+1);
                                alert('Неверно!');
                            }
                        }}>Шашки</button>
                        <button onClick={() => {
                            if (mistakes2 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes2(mistakes2+1);
                                alert('Неверно!');
                            }
                        }}>Красный Крест</button>
                    </div>
                ) : <></>}
                {qCnt === 3 ? (
                    <div className='q'>
                        <button onClick={() => {
                            if (mistakes3 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes3(mistakes3+1);
                                alert('Неверно!');
                            }
                        }}>Блюдце</button>
                        <button onClick={() => {
                            if (mistakes3 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes3(mistakes3+1);
                                alert('Неверно!');
                            }
                        }}>Шашки</button>
                        <button onClick={() => {
                            alert('Верно!');
                            setQCnt(qCnt + 1);
                        }}>Телеграмма</button>
                        <button onClick={() => {
                            if (mistakes3 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes3(mistakes3+1);
                                alert('Неверно!');
                            }
                        }}>Красный Крест</button>
                    </div>
                ) : <></>}
                {qCnt === 4 ? (
                    <div className='q'>
                        <button onClick={() => {
                            alert('Верно!');
                            setQCnt(qCnt + 1);
                        }}>Красный Крест</button>
                        <button onClick={() => {
                            if (mistakes4 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes4(mistakes4+1);
                                alert('Неверно!');
                            }
                        }}>Шашки</button>
                        <button onClick={() => {
                            if (mistakes4 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes4(mistakes4+1);
                                alert('Неверно!');
                            }
                        }}>Блюдце</button>
                        <button onClick={() => {
                            if (mistakes4 === 4) {
                                alert('Неверно');
                                setQCnt(qCnt + 1);
                            } else {
                                setMistakes4(mistakes4+1);
                                alert('Неверно!');
                            }
                        }}>Телеграмма</button>
                    </div>
                ) : <></>}
                {qCnt === 6 ? <div className='q'><h4>Вас автоматически переведет на следующий вопрос через {remainingTime}</h4></div> : <></>}
                {qCnt === 7 ? (
                    <div className='q7'>
                        <div className="row">
                        <input type="text" value={word[0]} onChange={(e) => handleInputChange(0, e)} />
                        <h5 onClick={() => {handleHintClick(0); alert('Это слово категории Организация')}}>Подсказка</h5>
                        </div>
                        <div className="row">
                        <input type="text" value={word[1]} onChange={(e) => handleInputChange(1, e)} />
                        <h5 onClick={() => {handleHintClick(1); alert('Это слово категории Сообщение')}}>Подсказка</h5>
                        </div>
                        <div className="row">
                        <input type="text" value={word[2]} onChange={(e) => handleInputChange(2, e)} />
                        <h5 onClick={() => {handleHintClick(2); alert('Это слово категории Посуда')}}>Подсказка</h5>
                        </div>
                        <div className="row">
                        <input type="text" value={word[3]} onChange={(e) => handleInputChange(3, e)} />
                        <h5 onClick={() => {handleHintClick(3); alert('Это слово категории Игра')}}>Подсказка</h5>
                        </div>
                    </div>
                ) : (<></>)}
                {/* Остальной JSX код */}
                {qCnt === 8 ? <div className='qResult'>
                    <h3>Ваш результат: {result}</h3>
                    <h4>{result <= 4 ? "Возможные когнитивные расстройства." : "Когнитивные расстройства отсутствуют."}</h4>
                </div> : <></>}
                {qCnt < 8 ? <button className={qCnt > 0 ? 'prev active' : 'prev'} onClick={() => qCnt > 0 ? setQCnt(qCnt-1) : setQCnt(qCnt)}>Назад</button> : <></>}
                {qCnt < 8 ? <button className='next active' onClick={() => answers[qCnt] !== -1 ? setQCnt(qCnt+1) : alert('Введите ответ')}>Далее</button> : <></>}
            </div>
        </div>
    )
}