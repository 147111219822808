import './Test10.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import closeIcon from '../../assets/icons/close.png';
import pen from '../../assets/imgs/pen.jpg';
import clock from '../../assets/imgs/clock.jpg';
import risunok from '../../assets/imgs/risunok.jpg';
import gt1 from '../../assets/imgs/gt1.png';
import gt2 from '../../assets/imgs/gt2.png';
import gt3 from '../../assets/imgs/gt3.png';
import gt4 from '../../assets/imgs/gt4.png';

export default function Test10() {
    const questions = [
        "Какое сегодня число?",
        "Какой сейчас номер месяца?",
        "Какой сейчас год?",
        "Какой день недели?",
        "Какое сейчас время года?",
        "Где вы находитесь? Страна Город Улица Дом Квартира",
        "Запомните данные три слова:",
        "Какие слова просилось запомнить?",
        "Введите слово ЗЕМЛЯ наоборот:",
        "Припомните три слова, которые Вы запоминали ранее:",
        "Как это называется?",
        "Как это называется?",
        "Запомните фразу:",
        "Запишите фразу, которую Вы запомнили:",
        "Возьмите правой рукой лист бумаги, сложите его вдвое и положите на стол.",
        "Запомните рисунок:",
        "Какой из рисунков был показан ранее?"
    ]
    const [qCnt, setQCnt] = useState(0);
    const [answers, setAnswers] = useState([-1, -1, -1, -1, -1, -1, 1, -1, -1, -1, -1, -1, 1, -1, -1, 1, -1]);
    const [q1btns, setQ1btns] = useState(0);
    const [q4btns, setQ4btns] = useState(0);
    const handleButtonClick = (value) => {
        const newAnswers = [...answers];
        newAnswers[qCnt] = value;
        setAnswers(newAnswers);
    }
    const handleChange = (event) => {
        const { value } = event.target;
        handleButtonClick(value);
    }
    function calculateResult() {
        let sum = 0;
      
        // Условие 1
        if (answers[0].trimEnd() == new Date().getDate()) {
          sum += 1;
        }
      
        // Условие 2
        if (answers[1].trimEnd() == new Date().getMonth() + 1) {
          sum += 1; 
        }
      
        // Условие 3
        if (answers[2].trimEnd() == new Date().getFullYear()) {
          sum += 1;
        }
      
        // Условие 4
        const daysOfWeek = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];
        const currentDayOfWeek = daysOfWeek[new Date().getDay()];
        if (answers[3] && answers[3].toLowerCase().trimEnd() === currentDayOfWeek) {
          sum += 1;
        }
      
        // Условие 5
        const currentSeason = getCurrentSeason();
        if (answers[4] && answers[4].toLowerCase().trimEnd() === currentSeason.toLowerCase()) {
          sum += 1;
        }
      
        // Условие 6
        if (answers[5] !== -1 && answers[5] !== "") {
          sum += 5;
        }
      
        // Условие 7
        if (answers[7] && answers[7].toLowerCase().trimEnd() === "карандаш дом копейка") {
          sum += 3;
        }
      
        // Условие 8
        if (answers[8] && answers[8].toLowerCase().trimEnd() === "ялмез") {
          sum += 5;
        }
      
        // Условие 9
        if (answers[9] && answers[9].toLowerCase().trimEnd() === "карандаш дом копейка") {
          sum += 3;
        }
      
        // Условие 10
        if (answers[10] && answers[10].toLowerCase().trimEnd() === "ручка") {
          sum += 1;
        }
      
        // Условие 11
        if (answers[11] && answers[11].toLowerCase().trimEnd() === "часы") {
          sum += 1;
        }
      
        // Условие 12
        if (answers[13] && answers[13].toLowerCase().trimEnd() === "никаких если, и или но") {
          sum += 1;
        }
      
        // Условие 13
        if (answers[14] && answers[14].toLowerCase().trimEnd() === "да") {
          sum += 3;
        }
      
        // Условие 14
        if (answers[16] === 1) {
          sum += 1;
        }
      
        return sum;
      }
      
      function getCurrentSeason() {
        const month = new Date().getMonth() + 1;
        switch (month) {
          case 12:
          case 1:
          case 2:
            return 'зима';
          case 3:
          case 4:
          case 5:
            return 'весна';
          case 6:
          case 7:
          case 8:
            return 'лето';
          case 9:
          case 10:
          case 11:
            return 'осень';
          default:
            return '';
        }
      }

    const textResult = [
      'По результатам тестирования выявлены выраженные когнитивные расстройства. Рекомендовано в оперативные сроки обратиться к специалисту (неврологу, нейропсихологу или психотерапевту) для более глубокого обследования, выявления причин когнитивных нарушений и постановки диагноза.',
      'По результатам тестирования выявлены умеренные когнитивные расстройства. Они представляют собой переходное состояние, при котором происходит снижение когнитивной функции, не приводящее к социальной дезадаптации. Умеренные когнитивные нарушения не обязательно вызваны развивающейся деменцией. Они могут быть признаком стресса, тревоги и депрессии, снижения функции щитовидной железы, дефицитных состояний, например недостатка витамина В12 или перенесенной инфекции COVID-19. Рекомендуется обратиться к специалисту, чтобы получить более точную диагностику. ',
      'По результатам тестирования выявлены легкие когнитивные расстройства, которые могут свидетельствовать о деменции легкой степени выраженности. Рекомендуется обратиться к специалисту, чтобы получить более точную диагностику.',
      'По результатам тестирования выявлены преддементные когнитивные нарушения. В самочувствии они могут выражаться в снижении памяти, рассеянности. Такие сигналы организма лучше не оставлять без внимания. Рекомендуется обратиться к специалисту, чтобы получить более точную диагностику.',
      'По результатам тестирования когнитивные расстройства не выявлены. Показатели в пределах нормы, соответствуют  оптимальному состоянию когнитивных функций.'
    ]
    return (
        <div className="Test10">
            <div className="block">
                {qCnt < 17 ? <div className='progress' style={{ width: `${((qCnt+1)/17)*100}%` }}></div> : 
                <div className='progress' style={{ width: '100%' }}></div>}
                {qCnt < 17 ? <h2><span>{qCnt+1} / 17. </span>{questions[qCnt]}</h2> : <></>}
                {qCnt === 0 ? <div className='q'>
                    <input type="text" value={answers[0] === -1 ? null : answers[0]} onChange={handleChange} placeholder='Число'/>
                </div> : <></>}
                {qCnt === 1 ? <div className='q'>
                    <input type="text" value={answers[1] === -1 ? null : answers[1]} onChange={handleChange} placeholder='Номер месяца'/>
                </div> : <></>}
                {qCnt === 2 ? <div className='q'>
                    <input type="text" value={answers[2] === -1 ? null : answers[2]} onChange={handleChange} placeholder='Год'/>
                </div> : <></>}
                {qCnt === 3 ? <div className='q'>
                    <input type="text" value={answers[3] === -1 ? null : answers[3]} onChange={handleChange} placeholder='День недели'/>
                </div> : <></>}
                {qCnt === 4 ? <div className='q'>
                    <input type="text" value={answers[4] === -1 ? null : answers[4]} onChange={handleChange} placeholder='Осень/Зима/Весна/Лето'/>
                </div> : <></>}
                {qCnt === 5 ? <div className='q'>
                    <input type="text" value={answers[5] === -1 ? null : answers[5]} onChange={handleChange} placeholder='Страна Город Улица Дом Квартира'/>
                </div> : <></>}
                {qCnt === 6 ? <div className='q'>
                    <h3>Карандаш Дом Копейка</h3>
                </div> : <></>}
                {qCnt === 7 ? <div className='q'>
                    <input type="text" value={answers[7] === -1 ? null : answers[7]} onChange={handleChange} placeholder='слово слово слово'/>
                </div> : <></>}
                {qCnt === 8 ? <div className='q'>
                    <input type="text" value={answers[8] === -1 ? null : answers[8]} onChange={handleChange}/>
                </div> : <></>}
                {qCnt === 9 ? <div className='q'>
                    <input type="text" value={answers[9] === -1 ? null : answers[9]} onChange={handleChange} placeholder='слово слово слово'/>
                </div> : <></>}
                {qCnt === 10 ? <div className='q'>
                    <img src={pen} alt="pen" />
                    <input type="text" value={answers[10] === -1 ? null : answers[10]} onChange={handleChange} placeholder='Что это?'/>
                </div> : <></>}
                {qCnt === 11 ? <div className='q'>
                    <img src={clock} alt="clock" />
                    <input type="text" value={answers[11] === -1 ? null : answers[11]} onChange={handleChange} placeholder='Что это?'/>
                </div> : <></>}
                {qCnt === 12 ? <div className='q'>
                    <h3>Никаких если, и или но</h3>
                </div> : <></>}
                {qCnt === 13 ? <div className='q'>
                    <input type="text" value={answers[13] === -1 ? null : answers[13]} onChange={handleChange}/>
                </div> : <></>}
                {qCnt === 14 ? <div className='q'>
                    <input type="text" value={answers[14] === -1 ? null : answers[14]} onChange={handleChange} placeholder='Получилось? Да/Нет'/>
                </div> : <></>}
                {qCnt === 15 ? <div className='q'>
                    <img src={risunok} alt="risunok" style={{width: "200px", height: "auto"}}/>
                </div> : <></>}
                {qCnt === 16 ? <div className='q16'>
                    <img src={gt2} alt="risunok" style={{width: "auto", height: "100px"}} onClick={() => setQCnt(qCnt+1)}/>
                    <img src={gt3} alt="risunok" style={{width: "auto", height: "100px"}} onClick={() => setQCnt(qCnt+1)}/>
                    <img src={gt1} alt="risunok" style={{width: "auto", height: "100px"}} onClick={() => {handleButtonClick(1); setQCnt(qCnt+1)}}/>
                    <img src={gt4} alt="risunok" style={{width: "auto", height: "100px"}} onClick={() => setQCnt(qCnt+1)}/>
                </div> : <></>}
                {qCnt > 16 ? <h2>Ваш результат: {calculateResult()}</h2> : <></>} {/* Далее h4 с результатами answers.reduce((partialSum, a) => partialSum + a, 0) - сумма */}
                {(qCnt > 16 && calculateResult() < 11) ? textResult[0] : <></>}
                {(qCnt > 16 && calculateResult() > 10 && calculateResult() < 20) ? textResult[1] : <></>}
                {(qCnt > 16 && calculateResult() > 19 && calculateResult() < 24 ) ? textResult[2] : <></>}
                {(qCnt > 16 && calculateResult() > 23 && calculateResult() < 28) ? textResult[3] : <></>}
                {(qCnt > 16 && calculateResult() > 27) ? textResult[4] : <></>}
                {qCnt < 17 ? <button className={qCnt > 0 ? 'prev active' : 'prev'} onClick={() => qCnt > 0 ? setQCnt(qCnt-1) : setQCnt(qCnt)}>Назад</button> : <></>}
                {qCnt < 17 ? <button className='next active' onClick={() => answers[qCnt] !== -1 ? setQCnt(qCnt+1) : alert('Введите ответ')}>Далее</button> : <></>}
            </div>
        </div>
    )
}