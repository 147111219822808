import './Test8.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Test8() {
    const questions = [
        "Постуральный тремор рук",
        "Постуральный тремор рук",
        "Кинетичекий тремор рук",
        "Кинетичекий тремор рук",
        "Выразительность лица",
        "Выразительность лица",
        "Общая спонтанность движений",
        "Общая спонтанность движений",
        "Ригидность",
        "Ригидность"
    ]
    const [qCnt, setQCnt] = useState(0);
    const [answers, setAnswers] = useState([1,-1,1,-1,1,-1,1,-1,1,-1]);
    const [q1btns, setQ1btns] = useState(0);
    const [q2btns, setQ2btns] = useState(0);
    const [q3btns, setQ3btns] = useState(0);
    const [q4btns, setQ4btns] = useState(0);
    const [q5btns, setQ5btns] = useState(0);
    const handleButtonClick = (value) => {
        const newAnswers = [...answers];
        newAnswers[qCnt] = value;
        setAnswers(newAnswers);
    }
    return (
        <div className="Test8">
            <div className="block">
                {qCnt < 10 ? <div className='progress' style={{ width: `${((qCnt+1)/10)*100}%` }}></div> : 
                <div className='progress' style={{ width: '100%' }}></div>}
                {qCnt < 10 ? <h2><span>{qCnt+1} / 10. </span>{questions[qCnt]}</h2> : <></>}
                {qCnt > 9 ? <h2>Ваш результат: {q1btns + q2btns + q3btns + q4btns + q5btns}</h2> : <></>} {/* Далее h4 с результатами answers.reduce((partialSum, a) => partialSum + a, 0) - сумма */}
                {(qCnt > 9 && (q1btns + q2btns + q3btns + q4btns + q5btns) < 3) ? <h4> По результатам тестирования признаки паркинсонизма не выявлены. Показатели в пределах нормы, соответствуют оптимальному состоянию когнитивных функций.</h4> : <></>}
                {(qCnt > 9 && (q1btns + q2btns + q3btns + q4btns + q5btns) > 2 && (q1btns + q2btns + q3btns + q4btns + q5btns) < 8) ? <h4>По результатам тестирования выявлены признаки паркинсонизма. Это может быть основанием для постановки диагноза "деменция с тельцами Леви". Рекомендуется обратиться к специалисту, чтобы получить более точную диагностику.</h4> : <></>}
                {(qCnt > 9 && (q1btns + q2btns + q3btns + q4btns + q5btns) > 7) ? <h4>По результатам тестирования выявлены выраженные признаки паркинсонизма. Это весомое основание для постановки диагноза "деменция с тельцами Леви". Рекомендовано в оперативные сроки обратиться к специалисту (неврологу, нейропсихологу или психотерапевту) для более глубокого обследования, выявления причин когнитивных нарушений и постановки диагноза.</h4> : <></>}
                {qCnt === 0 ? <h4>Попросите пациента вытянуть прямые руки перед собой ладонями вниз. Кисти должны быть выпрямлены, а пальцы удобно для пациента разведены, так чтобы они не касались друг друга. Понаблюдайте за этой позой 10 секунд. Необходимо учитывать любой тремор, отмечающийся в указанной позе, включая тремор покоя, возобновляющийся при удержании позы. Каждая рука оценивается отдельно. При оценке принимается во внимание максимальная наблюдаемая амплитуда дрожания.</h4> : <></>}
                {qCnt === 1 ? <div className='q1'>
                    <button className={q1btns === 0 ? 'active' : ''} onClick={() => setQ1btns(0)}>Норма: Нет тремора</button>
                    <button className={q1btns === 1 ? 'active' : ''} onClick={() => setQ1btns(1)}>Очень легкий: Тремор присутствует, но его амплитуда менее 1 см</button>
                    <button className={q1btns === 2 ? 'active' : ''} onClick={() => setQ1btns(2)}>Легкий: Амплитуда тремора как минимум 1 см, но менее 3 см</button>
                    <button className={q1btns === 3 ? 'active' : ''} onClick={() => setQ1btns(3)}>Умеренный: Амплитуда тремора как минимум 3 см, но менее 10 см</button>
                    <button className={q1btns === 4 ? 'active' : ''} onClick={() => setQ1btns(4)}>Тяжелый: Тремор с амплитудой 10 см и более</button>
                </div> : <></>}
                {qCnt === 2 ? <h4>Оценивается тремор, выявляемый с помощью пальценосовой пробы. Вначале пациента просят вытянуть руку вперед, а затем поочередно дотрагиваться до своего носа и до пальца исследующего. Проба выполняется каждой рукой не менее трех раз. Пальценосовая проба должна выполняться достаточно медленно, чтобы не пропустить дрожание, которое может становиться незаметным при очень быстром движении руки. Затем проба повторяется другой рукой. Каждая рука оценивается отдельно. Тремор может отмечаться во время всего движения или появляться лишь при достижении одной из целей (носа или пальца). При оценке принимается во внимание максимальная наблюдаемая амплитуда дрожания.</h4> : <></>}
                {qCnt === 3 ? <div className='q1'>
                    <button className={q2btns === 0 ? 'active' : ''} onClick={() => setQ2btns(0)}>Норма: Нет тремора</button>
                    <button className={q2btns === 1 ? 'active' : ''} onClick={() => setQ2btns(1)}>Очень легкий: Тремор присутствует, но его амплитуда менее 1 см</button>
                    <button className={q2btns === 2 ? 'active' : ''} onClick={() => setQ2btns(2)}>Легкий: Амплитуда тремора как минимум 1 см, но менее 3 см</button>
                    <button className={q2btns === 3 ? 'active' : ''} onClick={() => setQ2btns(3)}>Умеренный: Амплитуда тремора как минимум 3 см, но менее 10 см</button>
                    <button className={q2btns === 4 ? 'active' : ''} onClick={() => setQ2btns(4)}>Тяжелый: Тремор с амплитудой 10 см и более</button>
                </div> : <></>}
                {qCnt === 4 ? <h4>В течение 10 секунд понаблюдайте за пациентом, сидящим в покое, не вступая в разговор, а также во время беседы. Отметьте частоту мигания, маскообразность лица или утрату его выразительности, спонтанную улыбку и смыкание губ.</h4> : <></>}
                {qCnt === 5 ? <div className='q1'>
                    <button className={q3btns === 0 ? 'active' : ''} onClick={() => setQ3btns(0)}>Норма: Нормальная выразительность лица</button>
                    <button className={q3btns === 1 ? 'active' : ''} onClick={() => setQ3btns(1)}>Очень легкий: Едва заметная маскообразность: лишь снижение частоты мигания</button>
                    <button className={q3btns === 2 ? 'active' : ''} onClick={() => setQ3btns(2)}>Легкий: Снижение частоты мигания, ослабление движений рта, например, меньшей спонтанной улыбчивостью, но рот не полуоткрыт</button>
                    <button className={q3btns === 3 ? 'active' : ''} onClick={() => setQ3btns(3)}>Умеренный: Маскообразное лицо, рот в покое полуоткрыт в течение некоторого времени</button>
                    <button className={q3btns === 4 ? 'active' : ''} onClick={() => setQ3btns(4)}>Тяжелый: Маскообразное лицо, рот в покое полуоткрыт бóльшую часть времени</button>
                </div> : <></>}
                {qCnt === 6 ? <h4>Данная оценка носит общий характер и определяется замедленностью, задержкой, малой амплитудой, обеднением движений в целом, включая ослабление жестикуляции и скрещивание ног, которые могут выявляться в течение всего осмотра. Оценка основывается на общем впечатлении исследующего после наблюдения за спонтанными жестами пациента при сидении, вставании и ходьбе.</h4> : <></>}
                {qCnt === 7 ? <div className='q1'>
                    <button className={q4btns === 0 ? 'active' : ''} onClick={() => setQ4btns(0)}>Норма: Нет нарушений</button>
                    <button className={q4btns === 1 ? 'active' : ''} onClick={() => setQ4btns(1)}>Очень легкий: Очень легкие общая замедленность и обеднение спонтанных движений</button>
                    <button className={q4btns === 2 ? 'active' : ''} onClick={() => setQ4btns(2)}>Легкий: Легкие общая замедленность и обеднение спонтанных движений</button>
                    <button className={q4btns === 3 ? 'active' : ''} onClick={() => setQ4btns(3)}>Умеренный: Умеренные общая замедленность и обеднение спонтанных движений</button>
                    <button className={q4btns === 4 ? 'active' : ''} onClick={() => setQ4btns(4)}>Тяжелый: Тяжелые общая замедленность и обеднение спонтанных движений</button>
                </div> : <></>}
                {qCnt === 8 ? <h4>Ригидность определяется по замедлению пассивных движений в крупных суставах, когда пациент находится в расслабленном состоянии, а исследующий совершает движения в его конечностях и шее. Вначале проверка проводится без провоцирующих приемов. Исследование и оценка мышечного тонуса в шее и в каждой конечности проводятся отдельно. При исследовании руки движения в лучезапястных и локтевых суставах проверяются одновременно. При исследовании ноги движения в тазобедренных и коленных суставах проверяются одновременно. Если ригидность не выявляется, используйте провоцирующие приемы, например, постукивание пальцами, сжимание/разжимание кисти в кулак или постукивание пяткой – в той конечности, которая не проверяется. Попросите пациента как можно больше расслабиться, когда проверяете ригидность.</h4> : <></>}
                {qCnt === 9 ? <div className='q1'>
                    <button className={q5btns === 0 ? 'active' : ''} onClick={() => setQ5btns(0)}>Норма: Нет ригидности</button>
                    <button className={q5btns === 1 ? 'active' : ''} onClick={() => setQ5btns(1)}>Очень легкий: Ригидность выявляется только при провоцирующем приеме</button>
                    <button className={q5btns === 2 ? 'active' : ''} onClick={() => setQ5btns(2)}>Легкий: Ригидность выявляется без провоцирующих приемов, но полный объем движений легко достигается</button>
                    <button className={q5btns === 3 ? 'active' : ''} onClick={() => setQ5btns(3)}>Умеренный: Ригидность выявляется без провоцирующих приемов, полный объем движений достигается с усилием</button>
                    <button className={q5btns === 4 ? 'active' : ''} onClick={() => setQ5btns(4)}>Тяжелый: Ригидность выявляется без провоцирующих приемов, полный объем движений не достигается</button>
                </div> : <></>}
                {qCnt < 10 ? <button className={qCnt > 0 ? 'prev active' : 'prev'} onClick={() => qCnt > 0 ? setQCnt(qCnt-1) : setQCnt(qCnt)}>Назад</button> : <></>}
                {qCnt < 10 ? <button className='next active' onClick={() => setQCnt(qCnt+1)}>Далее</button> : <></>}
            </div>
        </div>
    )
}