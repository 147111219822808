import './Test11.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import closeIcon from '../../assets/icons/close.png';
import w1 from '../../assets/imgs/w1.png';
import w2 from '../../assets/imgs/w2.png';
import w3 from '../../assets/imgs/w3.png';
import w4 from '../../assets/imgs/w4.png';
import ww1 from '../../assets/imgs/ww1.png';
import ww2 from '../../assets/imgs/ww2.png';
import ww3 from '../../assets/imgs/ww3.png';
import ww4 from '../../assets/imgs/ww4.png';

export default function Test11() {
    const questions = [
        "Пожалуйста, посмотрите внимательно. Сейчас показаны три слова, которые нужно повторить вслух и постараться запомнить:",
        "Какие были даны три слова?",
        "Выберите правильно нарисованный циферблат часов:",
        "На каком рисунке изображены десять минут двенадцатого?",
        "Какие три слова ранее просилось запомнить?"
    ]
    const [qCnt, setQCnt] = useState(0);
    const [answers, setAnswers] = useState([0,0,-1,-1,-1]);
    const [q1btns, setQ1btns] = useState(0);
    const [q4btns, setQ4btns] = useState(0);
    const handleButtonClick = (value) => {
        const newAnswers = [...answers];
        newAnswers[qCnt] = value;
        setAnswers(newAnswers);
    }
    const handleButtonClickIMG = (value) => {
        const newAnswers = [...answers];
        newAnswers[qCnt] = value;
        setAnswers(newAnswers);
        setQCnt(qCnt+1);
    }
    const textResults = [
        'По результатам тестирования выявлены выраженные когнитивные расстройства. Рекомендовано в оперативные сроки обратиться к специалисту (неврологу, нейропсихологу или психотерапевту) для более глубокого обследования, выявления причин когнитивных нарушений и постановки диагноза.',
        'По результатам тестирования когнитивные расстройства не выявлены, показатели в пределах нормы. Значимые проблемы в когнитивной сфере отсутствуют. Важно помнить, что основополагающим методом профилактики деменции является ранняя диагностика и своевременное обращение к специалисту. Будьте здоровы!'
    ]
    return (
        <div className="Test11">
            <div className="block">
                {qCnt < 5 ? <div className='progress' style={{ width: `${((qCnt+1)/5)*100}%` }}></div> : 
                <div className='progress' style={{ width: '100%' }}></div>}
                {qCnt < 5 ? <h2><span>{qCnt+1} / 5. </span>{questions[qCnt]}</h2> : <></>}
                {qCnt > 4 ? <h2>Ваш результат: {answers.reduce((partialSum, a) => partialSum + a, 0)}</h2> : <></>} {/* Далее h4 с результатами answers.reduce((partialSum, a) => partialSum + a, 0) - сумма */}
                {(qCnt > 4 && answers.reduce((partialSum, a) => partialSum + a, 0) < 4) ? <h4>{textResults[0]}</h4> : <></>}
                {(qCnt > 4 && answers.reduce((partialSum, a) => partialSum + a, 0) > 3) ? <h4>{textResults[1]}</h4> : <></>}
                {qCnt === 0 ? <h4>Лимон, ключ, шар</h4> : <></>}
                {qCnt === 1 ? <div className='q1'>
                    <button className={q1btns === 1 ? 'active' : ''} onClick={() => setQ1btns(1)}>Тигр, кот, дверь</button>
                    <button className={q1btns === 2 ? 'active' : ''} onClick={() => setQ1btns(2)}>Лимон, ключ, шар</button>
                    <button className={q1btns === 3 ? 'active' : ''} onClick={() => setQ1btns(3)}>Собака, яблоко, дерево</button>
                    <button className={q1btns === 4 ? 'active' : ''} onClick={() => setQ1btns(4)}>Книга, стол, солнце</button>
                </div> : <></>}
                {qCnt === 2 ? <div className='q2'>
                    <img src={w2} alt="watch" onClick={() => handleButtonClickIMG(0)}/>
                    <img src={w3} alt="watch" onClick={() => handleButtonClickIMG(0)}/>
                    <img src={w1} alt="watch" onClick={() => handleButtonClickIMG(1)}/>                    
                    <img src={w4} alt="watch" onClick={() => handleButtonClickIMG(0)}/>
                </div> : <></>}
                {qCnt === 3 ? <div className='q3'>
                    <img src={ww2} alt="watch" onClick={() => handleButtonClickIMG(0)}/>
                    <img src={ww1} alt="watch" onClick={() => handleButtonClickIMG(1)}/> 
                    <img src={ww3} alt="watch" onClick={() => handleButtonClickIMG(0)}/>
                    <img src={ww4} alt="watch" onClick={() => handleButtonClickIMG(0)}/>
                </div> : <></>}
                {qCnt === 4 ? <div className='q1'>
                    <button className={q4btns === 2 ? 'active' : ''} onClick={() => {setQ4btns(2); handleButtonClick(3)}}>Лимон, ключ, шар</button>
                    <button className={q4btns === 1 ? 'active' : ''} onClick={() => {setQ4btns(1); handleButtonClick(0)}}>Тигр, кот, дверь</button>
                    <button className={q4btns === 4 ? 'active' : ''} onClick={() => {setQ4btns(4); handleButtonClick(0)}}>Книга, стол, солнце</button>
                    <button className={q4btns === 3 ? 'active' : ''} onClick={() => {setQ4btns(3); handleButtonClick(0)}}>Собака, яблоко, дерево</button>
                </div> : <></>}
                {qCnt < 5 ? <button className={qCnt > 0 ? 'prev active' : 'prev'} onClick={() => qCnt > 0 ? setQCnt(qCnt-1) : setQCnt(qCnt)}>Назад</button> : <></>}
                {qCnt < 5 ? <button className='next active' onClick={() => answers[qCnt] !== -1 ? setQCnt(qCnt+1) : alert('Введите ответ')}>Далее</button> : <></>}
            </div>
        </div>
    )
}